import logo from './logo.svg';
import './App.css';
import ComingSoon from './pages/ComingSoon';

function App() {
  return (
    <ComingSoon />
  );
}

export default App;

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { ComingSoonIllustration } from '../assets';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
}));

export default function ComingSoon() {
  return (
    <Container>
      <ContentStyle sx={{ textAlign: 'center' }}>
        <Box
          component="img"
          src={'/assets/trinita_logo.jpg'}
          sx={{ width: 250 }}
        />
        <Typography variant="h4">Coming Soon!</Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          We are currently working on this page!
        </Typography>

        <ComingSoonIllustration sx={{ my: 10, height: 240 }} />
      </ContentStyle>
    </Container>
  );
}
